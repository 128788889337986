body {
    margin: 0;
    font-family: 'VT323', monospace;
    font-size: 20px;
    background-color: #666666;
  }
  .app {
    width: 100vw;
    height: 100%;
  }
  .app-loader {
    position: absolute;
    margin: 0;
    background-color: rgba(211,211,211, 0.7);
    z-index: 9999;
    height: 100%;
    width: 100%;
   }
   .app-side-nav-icons {
    position: absolute;
    top: 20px;
    left: 10px;
   }
   .app-home-link {
    color: purple;
    cursor: pointer;
   }
   .app-admin-link {
    color: darkgray;
    cursor: pointer;
   }
   .app-resume-link {
    color: #105a37;
    cursor: pointer;
   }
   .app-logout-link {
    cursor: pointer;
   }
  
   .app-hidden {
    display: none;
   }
   .app-admin-container {
    padding: 20px;
   }
   .app-admin-view {
    position: absolute;
    top: 50px;
    left: 100px;
    padding-left: 2vw;
    padding-right: 2vw;
    width: 75vw;
    height: 80vh;
    background-color: whitesmoke;
    min-width: 75vw;
    min-height: 80vh;
    border-radius: 25px;
    display: inline-block;
   }
   .app-edit-biography-modal-container {
    max-width: 75vw;
   }
   .app-admin-view-edit-section-header {
    font-size: 20px;
    font-weight: bold;
    padding: 10px;
   }
   .app-admin-view-nav-container {
    padding: 20px;
    display: inline-block;
   }
   .app-admin-view-side-nav {
    width: 200px;
    padding: 10px;
    display: inline-block;
   }
   .app-admin-view-side-nav-item {
    padding: 10px;
    cursor: pointer;
    border-radius: 25px;
    border:#333333 solid 1px;
    margin: 10px;
    text-align: center;
   }
   .app-admin-view-side-nav-item:hover {
    background-color: #336699;
    color: #e2e2e2;
   }
   .app-admin-view-side-nav-item-selected {
    padding: 10px;
    cursor: pointer;
    border-radius: 25px;
    border:#333333 solid 1px;
    margin: 10px;
    text-align: center;
    background-color: #333333;
    color: #e2e2e2;
   }
   .app-admin-view-secondary-nav {
    width: 200px;
    position: relative;
    top: 10px;
    display: inline-block;
    vertical-align: top;
   }
  .app-admin-view-secondary-nav-item {
    padding: 5px;
    cursor: pointer;
    border-radius: 25px;
    border:#333333 solid 1px;
    margin: 10px;
    text-align: center;
    background-color: #e2e2e2;
  }
  .app-admin-view-secondary-nav-item:hover {
    background-color: #336699;
    color: #e2e2e2; 
  }
  .app-admin-view-secondary-nav-item-selected {
    padding: 10px;
    cursor: pointer;
    border-radius: 25px;
    border:#333333 solid 1px;
    margin: 10px;
    text-align: center;
    background-color: #333333;
    color: #e2e2e2;
   }
   .app-admin-view-edit-section {
    position: relative;
    top: 10px;
    display: inline-block;
    vertical-align: top;
   }
   .app-admin-view-edit-section-item {
    padding: 10px;
    cursor: pointer;
    margin: 10px;
   }
   .app-admin-view-edit-section-item-buttons {
    width: 90%;
    position: relative;
    text-align: left !important;
    top: 10vh;
    margin-left: 30px;
   }
   .app-admin-view-edit-section-item-button-previous {
    float: left;
    cursor: pointer;
   }
   .app-admin-view-edit-section-item-button-next {
    float: right;
    cursor: pointer;
   }
   .app-admin-view-edit-section-item-wrapper {
    display: block
   }
   .app-admin-view-edit-label-check {
    display: inline-block;
    width: 15px;
    height: 15px;
    border: 1px solid #333333;
    border-radius: 25%;
    margin-left: 20px;
   }
   .app-admin-view-edit-label-check-selected {
    display: inline-block;
    width: 15px;
    height: 15px;
    border: 2px #e2e2e2 inset;
    border-radius: 25%;
    background-color: #336699;
    margin-left: 20px;
   }
  
  .app-admin-view-edit-input {
    display: block;
    border:#333333 solid 1px;
    border-radius: 5px;
    padding: 5px;
    margin: 5px;
    width: 97%;
  }
  .app-admin-view-edit-input-deleted {
    display: block;
    border:#333333 solid 1px;
    border-radius: 5px;
    padding: 5px;
    margin: 5px;
    width: 97%;
    text-decoration: line-through;
  }
  .app-admin-view-edit-select {
    display: block;
    border:#333333 solid 1px;
    border-radius: 5px;
    padding: 5px;
    margin: 5px;
    width: 98%;
  }
  .app-admin-daterange-container {
    display: block;
  }
  .app-admin-daterange-start {
    display: inline-block;
    width: 45%;
  }
  .app-admin-daterange-end {
    display: inline-block;
    width: 45%;
  }
  .app-admin-daterange-label {
    display: inline-block;
    font-size: 12px;
    text-transform: uppercase;
    color:#666666;
    margin-right: 10px;
  }
  .app-admin-view-edit-textarea {
    display: block;
    border:#333333 solid 1px;
    border-radius: 5px;
    padding: 5px;
    margin: 5px;
    width: 97%;
    height: 200px;
  }
  .app-admin-view-edit-textarea-deleted {
    display: block;
    border:#333333 solid 1px;
    border-radius: 5px;
    padding: 5px;
    margin: 5px;
    width: 97%;
    height: 200px;
    text-decoration: line-through;
  }
  .app-admin-view-edit-buttons {
    position: absolute;
    right: 0px;
    top: 10px;
    z-index: 999;
    width: 70px;
    height: 300px;
  }
  
   .app-skillsets-item {
    padding: 20px;
    cursor: pointer;
   }
   .app-skillsets-item--front {
    color: blue;
   }
   .app-skillsets-item--front:hover {
    font-weight: bold;
   }
   .app-skillsets-item--middle {
    color: green;
   }
   .app-skillsets-item--middle:hover {
    font-weight: bold;
   }
   .app-skillsets-item--back {
    color: orange;
   }
   .app-skillsets-item--back:hover {
    font-weight: bold;
   }
   .app-skillsets-item--process {
    color: gray;
   }
   .app-skillsets-item--process:hover {
    font-weight: bold;
   }
  .app-resume-view {
    position: absolute;
    top: 50px;
    left: 100px;
    padding-left: 2vw;
    padding-right: 2vw;
    width: 75vw;
    height: 93vh;
    background-color: whitesmoke;
    min-width: 75vw;
    min-height: 80vh;
    border-radius: 25px;
  }
  .app-resume-loaders {
    display: inline-flex;
  }
  .app-resume-header {
    position: absolute;
    margin: 10px;
    left: 20px;
    font-size: 24px;
  }
  .app-loader-icon {
    position: absolute;
    top: 30vh;
    left: 35vw;
  }
  .app-resume-employer-project-description {
    overflow: auto;
    font-size: 11px;
  }
  
  .app-resume-header--last-updated {
    float: right;
    padding-top: 10px;
    font-size: 12px;
    padding-right: 15px;
  }
  .app-resume-job-search-status:not(:empty)::before {
    content: '(';
  }
  .app-resume-job-search-status:not(:empty)::after {
    content: ')';
  }
  .app-resume-current-employer--daterange:not(:empty)::after {
    content: ' - Present';
  }
  .app-resume-current-status {
    position: relative;
    padding: 10px;
    top: 40px;
    font-size: 12px;
    padding-bottom: 20px;
  }
  .app-resume-header-modal-buttons {
    display: inline-block;
    margin-top: 20px;
    padding: 20px;
  }
  .app-resume-header-modal-button-container {
    margin-top: 15px;
    display: inline;
    min-width: 125px;
    display: inline;
    padding: 20px;
  }
  .app-resume-modal-open-button {
    border: 1px dashed #336699;
    font-size: 14px;
    padding: 20px;
    text-align: center;
    background-color: #e2e2e2;
    text-transform: lowercase;
    font-weight: bold;
    cursor: pointer;
    padding: 20px;
  }
  .app-resume-modal-open-button:hover {
    background-color: #336699;
    color: #e2e2e2;
  }
  .app-modal-content-current-status-label {
    padding: 20px;
    font-size: 12px;
    text-transform: lowercase;
    color: #666666;
  }
  .app-modal-content-current-status-label-text {
    min-width: 300px;
    max-width: 400px;
    width: 400px;
  }
  .app-modal-content-current-status-value {
    padding: 20px;
    font-size: 14px;
    color: #333333;
    text-transform: uppercase;
  }
  .app-resume-bio-header {
    padding: 20px;
    width: 100%;
    font-size: 14px;
    background-color: #336699;
    color: #e2e2e2;
    font-weight: bold;
    text-transform: capitalize;
  }
  .app-modal-close-button {
    float: right;
    top: -50px;
    position: relative;
    border: none; 
    cursor: pointer;
  }
  .app-resume-all-skillsets {
    padding-top: 30px;
    font-size: 45px;
  }
  .app-resume-employer-header-container::before {
    content: 'current employer';
    display: block;
    font-size: 12px;
    font-weight: bold;
  }
  .app-resume-employer-header-container-previous {
    display: block;
  }
  .app-resume-previous-employer--name {
    font-weight: bold;
  }
  .app-resume-current-employer {
    position: relative;
    padding: 10px;
    font-size: 15px;
    padding: 10px;
  }
  .app-resume-current-employer--name {
    font-weight: bold;
    padding: 20px;
  }
  .app-resume-current-employer--item {
    padding: 20px;
    font-size: 14px;
  }
  .app-resume-current-employer--daterange {
    padding-right: 20px;
    font-size: 14px;
    float: right !important;
  }
  .app-resume-employer--daterange {
    padding-right: 20px;
    font-size: 14px;
    float: right !important;
  }
  
  .app-resume-employer-details {
    padding: 20px;
    font-size: 12px;
    min-height: 100px;
  }
  .app-resume-employer-skillsets {
    padding: 10px;
    font-size: 30px;
  }
  
  .app-resume-skillset-icons {
    width: 300px;
  }
  
  .app-resume-skillset-icons-icon {
    padding-left: 20px;
    padding-top: 10px;
    padding-bottom: 5px;
  }
  
  .app-resume-project-card {
    box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
    transition: 0.3s;
    overflow: hidden;
    margin-left: 25px;
    margin-right: 25px;
    padding-bottom: 30px;
  }
  .app-resume-project-card:hover {
    box-shadow: 0 8px 16px 0 rgba(0,0,0,0.2);
  }
  .app-resume-project-card-container {
    padding: 2px 16px;
    display: inline-block;
    width: 95%;
    top: 20px;
  }
  .app-resume-project-card-previous {
    font-size: 40px;
    position: absolute;
    left: 40px;
    bottom: 1vh;
    cursor: pointer;
    color:#555;
  }
  .app-resume-project-card-previous:hover {
    color:#336699;
    font-weight: bold;
  }
  .app-resume-project-card-next {
    font-size: 40px;
    position: absolute;
    right: 30px;
    bottom: 1vh;
    cursor: pointer;
    color:#555;
  }
  .app-resume-project-card-next:hover {
    color:#336699;
    font-weight: bold;
  }
  .app-resume-project-card-header-container {
    display: inline-block;
    width: 100%;
  }
  .app-resume-employer-project-title {
    font-weight: bold;
    border-bottom: 1px solid gray;
  }
  .app-resume-employer-project-title::before {
    content: 'project title';
    display: block;
    font-size: 10px;
  }
  .app-resume-employer-project-role {
    font-weight: normal;
    text-transform: lowercase;
    font-size: 12px;
    display: inline-block;
    padding-left: 10px;
    color: #666666;
  }
  .app-resume-employer-project-role::before {
    content: 'project role';
    display: block;
    font-size: 10px;
  }
  .app-resume-employer-project-status {
    float: right;
    font-weight: bold;
  }
  .app-resume-employer-project-status::before {
    content: 'status';
    display: block;
    font-size: 10px;
  }
  .app-resume-employer-project-teamsize {
    font-weight: bold;
  }
  .app-resume-employer-project-teamsize::before {
    content: 'team size (engineers)';
    display: block;
    font-size: 10px;
  }
  .app-resume-employer-project-daterange {
    padding-top: 0;
    float: right !important;
    font-weight: bold;
  }
  .app-resume-project-details-container {
    padding-top: 20px;
    padding-bottom: 20px;
  }
  .app-resume-project-details-container::before {
    content: 'project description';
    display: block;
    font-size: 10px;
  }
  .app-resume-project-details-no-project {
    padding: 30px; 
    font-weight: bold;
  }
  
  .app-resume-navigation-container {
    position: absolute;
    top: 25vh;
    width: 91vw;
  }
  .app-resume-navigation-previous {
    background-color: #336699;
    padding: 20px;
    border-radius: 25px;
    text-transform: uppercase;
    width: 150px;
    cursor: pointer;  
    color: aliceblue;
  }
  .app-resume-navigation-previous--disabled {
    background-color: gray;
    padding: 20px;
    border-radius: 25px;
    text-transform: uppercase;
    width: 150px;
    cursor: not-allowed;
  }
  .app-resume-navigation-next {
    background-color: green;
    padding: 20px;
    border-radius: 25px;
    text-transform: uppercase;
    left: 25px;
    width: 150px;
    cursor: pointer;
    float: right;
  }
  .app-resume-navigation-next--disabled {
    background-color: gray;
    padding: 20px;
    border-radius: 25px;
    text-transform: uppercase;
    left: 25px;
    width: 150px;
    cursor: not-allowed;
    float: right;
  }
  .app-console-view {
    position: absolute;
    top: 50px;
    left: 100px;
    padding-left: 20px;
    width: 75vw;
    height: 75vh;
    min-width: 75vw;
    min-height: 75vh;
    border-radius: 25px;
  }
  
  .app-console-cursor {
    position: relative;
    top: 70vh;
    width: 77vw;
    left: -60px;
  }
  .app-console-intro-text{
    color: green;
    border: none;
    font-size: 72px;
  }
  .app-console-inset-1x {
    padding-left: 20px;
  }
  .app-console-inset-2x {
    padding-left: 40px;
  }
  
  .app-console-input-text {
    padding-left: 50px;
    color: aliceblue;
    border: none;
    min-width: 10px;
    max-width: 73vw;
  }
                
  .app-console-command-text {
    word-wrap: break-word;
  }
  .app-console-command-prompt::after {
    content: ': ';
  }
  .app-console-command-text::after {
    content: ' ';
    display: inline-block;
    margin-left: 4px;
    margin-top: 2px;
    width: 10px;
    height: 12px;
    padding: 0;
    background-color: green;
    animation: blinker 1s linear infinite;
  }

  .app-console-command-pwd {
    -webkit-text-security: disc;
    text-security: disc;
  }
  
  .app-console-blinker {
     background-color: green;
     animation: blinker 1s linear infinite;
     line-height: 10px;
     position: relative;
     padding-left: 10px;
  }
  
  .app-console-history {
    min-height: 20px;
    max-height: 200px;
    width: 65vw;
    font-size: 12px;
    color: green;
    padding: 10px;
    overflow-y: auto;
    overflow-x: hidden;
  }
  
  .app-console-history-item {
    padding: 0;
    margin: 0;
  }
  
  .app-console-history-end {
    height: 20px;
  }
  
  .app-console-hidden-input {
    position: absolute;
    top: -300px;
  }
  
  .app-console-play-area {
    position: absolute;
    left: 30vw;
    top: 10vh;
    border: #e2e2e2 1px solid;
    border-radius: 10px;
    width: 40vw;
    height: 50vh;
  }
  
  .app-console-no-no {
    position: absolute;
    left: 30vw;
    top: 5vh;
    color: red;
  }
  .app-playground-header {
    margin: 10px;
    display: inline-block;
    width: 90%;
    border-bottom: 1px solid #e2e2e2;
  }
  .app-playground-header-item {
    display: inline-block;
    width: 25%;
    text-align: center;
    font-size: 15px;
    font-weight: bold;
    color: #e2e2e2;
    vertical-align: middle;
  }
  .app-admin-modal {
    position: absolute;
    min-height: 80vh !important;;
  }
  .app-modal-content {
    max-width: 85vw;
  }
  .app-resume-modal-close-button {
    position: absolute;
    z-index: 9999;
    top: 15px;
    right: 0px;
    background-color: #336699;
    color: #e2e2e2;
    text-transform: uppercase;
    font-weight: bold;
    cursor: pointer;
  }
  
  .app-edit-biography-statement-container {
    display: inline !important;
  }
  
  .app-edit-biography-statement {
    width: 70vw;
    height: 100px;
  }
  .app-edit-biography-statement-edit {
    width: 60vw;
    height: 120px;
    border: 1px solid black;
  }
  .app-edit-biography-statement-deleted {
    width: 70vw;
    height: 100px;
    text-decoration: line-through;
    font-size: 13px;
    word-spacing: 0.1rem;
    text-rendering: auto;
    color: fieldtext;
    letter-spacing: normal;
    line-height: normal;
    text-transform: none;
    text-indent: 0px;
    text-shadow: none;
    display: inline-block;
    text-align: start;
    appearance: auto;
    -webkit-rtl-ordering: logical;
    resize: auto;
    cursor: text;
    overflow-wrap: break-word;
    background-color: field;
    column-count: initial !important;
    writing-mode: horizontal-tb !important;
    border-width: 1px;
    border-style: solid;
    border-color: -internal-light-dark(rgb(118, 118, 118), rgb(133, 133, 133));
    border-image: initial;
    padding: 2px;
    white-space: pre-wrap;
  }
  
  .app-edit-biography-statement-ta-container {
    display: inline-block;
    margin: 5px;
  }
  
  .app-edit-biography-statement-button-container {
    display: inline-block;
    max-width: 100px;
    position: absolute;
    right: 5vw;
  }
  
  .app-edit-biography-statement-sort-buttons {
    padding-top: 15px;
    max-width: 30px;
  }
  
  .app-edit-biography-statement-delete-buttons {
    display: inline;
    position: absolute;
    top: 25px;
    max-width: 30px;
    padding-left: 30px;
  }
  
  .app-edit-biography-save-button-container {
    float: right;
    padding-right: 20px;
    padding-top: 25px;
  }
  
  .app-edit-biography-statement-save-message-container {
    display: inline-block;
    position: absolute;
    top: 10px;
    left: 60px;  
    font-size: 12px;
    width: 300px;
    color: blueviolet;
    font-weight: bolder;
  }
  .app-edit-container {
    position: relative;
    top: 10px;
    width: 70vw;
    height: 80vh;
  }
  .app-roles-edit {
    min-width: 70vw;
    min-height: 85vh;
  }
  .app-employers-edit {
    min-width: 70vw;
    min-height: 85vh;
  }
  .app-projects-edit {
    min-width: 70vw;
    min-height: 85vh;
  }
  .app-modal-edit {
    min-width: 70vw;
    min-height: 85vh;
  }
  
  .app-biography-selected-statement-container {
    max-width: 60vw;
    display: inline-block;
  }
  .app-biography-previous-statement {
    display:inline-block;
    width: 5vw;
    align-items: self-start;
    font-size: 14px;
    cursor: pointer;
  }
  .app-biography-selected-statement {
    display:inline-block;
    width: 50vw;
  }
  .app-biography-next-statement {
    display:inline-block;
    width: 5vw;
    align-items: self-end;
    font-size: 14px;
    padding-left: 55vw;
    cursor: pointer;
  }
  
  .app-push-down-50 {
    padding-top: 50px;
  }
  
  .app-push-down-40 {
    padding-top: 40px;
  }
  
  .app-push-down-30 {
    padding-top: 30px;
  }
  
  .app-push-down-20 {
    padding-top: 20px;
  }
  
  .app-push-down-5 {
    padding-top: 5px;
  }
  .app-push-right-30 {
    padding-left: 30px;
  }
  
  .app-push-right-20 {
    padding-left: 20px;
  }
  .app-push-left-70 {
    padding-right: 70px;
  }
  .app-push-left-50 {
    padding-right: 50px;
  }
  .app-push-left-30 {
    padding-right: 30px;
  }
  
  .app-float-right {
    float: right;
  }
  
  .app-small-icon-move-up {
    color: #336699;
    cursor: pointer;
  }
  
  .app-small-icon-move-down {
    color: green;
    cursor: pointer;
  }
  
  .app-small-icon-add {
    color: purple;
    cursor: pointer;
  }
  
  .app-small-icon-save {
    color: blue;
    cursor: pointer;
  }
  
  .app-small-icon-disabled {
    cursor: not-allowed;
    color: lightgray !important;
  }
  
  .app-small-icon-delete {
    color: red;
    cursor: pointer;
  }
  
  .app-small-icon-undelete {
    color: green;
    cursor: pointer;
  }
  .app-console-command-list {
    font-family:'Courier New', Courier, monospace;
    font-size: 11px;
    padding: 0px;
  }
  .app-console-command {
    padding: 0px;
    margin: 0px;
  }
  .app-console-command--selected {
    font-weight: bold;
  }
  .app-admin-list-container {
    display: inline-block;
    width: 30vw;
    height: 76vh;
    border: 1px solid #e2e2e2;
  }
  .app-admin-list-header {
    font-size: 20px;
    font-weight: bold;
    padding: 10px;
    border-bottom: 1px solid gray;
  }
  .app-admin-list-header-buttons{
    display: inline-block;
    max-width: 30px;
    padding-left: 50px;
    padding-top: 10px;
    position: absolute;
    left: 25vw;
    top: 0px;
  }
  .app-admin-list-item {
    padding: 10px;
    cursor: pointer;
    border-bottom: 1px solid #e2e2e2;
  }
  .app-admin-list-item-name {
    display: inline-block;
    width: 70%;
  }
  .app-admin-list-item-name--deleted {
    text-decoration: line-through;
    display: inline-block;
    width: 70%;
  }
  .app-admin-list-item-buttons {
    display: inline-block;
    max-width: 30px;
  }
  
  .app-admin-list-item--selected {
    padding: 10px;
    cursor: pointer;
    border-bottom: 1px solid #e2e2e2;
    background-color: #336699;
    color: #e2e2e2;
  
  }
  .app-admin-selected-item-section {
    padding: 20px;
    font-size: 16px;
  }
  .app-admin-selected-item-container {
    display: inline-block;
    padding: 10px;
    width: 35vw;
    position: absolute;
    top: -40px;
  }
  .app-admin-textarea--deleted {
    text-decoration: line-through;
    width: 80%;
    height: 100px;
    border: 1px solid #e2e2e2;
  }
  .app-admin-textarea--edit {
    width: 80%;
    height: 100px;
    border: 1px solid #e2e2e2;
  }
  .app-admin-textarea-delete-buttons {
    display: inline-block;
    max-width: 30px;
    padding-left: 10px;
  }
  .app-admin-textarea-sort-buttons {
    display: inline-block;
    max-width: 30px;
  }
  
  .app-admin-selected-item-header {
    font-size: 15px;
    font-weight: bold;
    padding: 10px;
  }
  
  .app-admin-button-container {
    float: right;
    position: absolute;
    right: 5vw;
  }
  .app-admin-button-container--nested {
    float: right;
    position: absolute;
    right: 5vw;
    top: 10px;
  }
  
  .app-admin-user-record {
    padding: 10px;
    border-bottom: 1px solid #e2e2e2;
    margin: 10px;
  }
  
  .app-admin-user-displayname {
    display: inline-block;
    width: 30%;
    font-weight: bold;
  }
  
  .app-admin-user-username {
    display: inline-block;
    width: 30%;
    cursor: pointer;
  }
  .app-admin-user-username::before {
    content: 'username';
    font-size: 10px;
    position: relative;
    top: -20px;
  }
  .app-admin-user-role {
    display: inline-block;
    width: 20%;
  }
  .app-admin-user-role::before {
    content: 'role';
    font-size: 10px;
    position: relative;
    top: -20px;
  }
  .app-admin-user-lastlogin {
    display: inline-block;
    font-size: 12px;
    width: 20%;
  }
  .app-admin-user-lastlogin::before {
    content: 'last login';
    font-size: 10px;
    position: relative;
    top: -20px;
  }

  .app-lag-typing {
    overflow: hidden; /* Ensures the content is not revealed until the animation */
    white-space: nowrap; /* Keeps the content on a single line */
    margin: 0 auto; /* Gives that scrolling effect as the typing happens */
    letter-spacing: .15em; /* Adjust as needed */
    animation: 
      typing 3.5s steps(40, end)
  }

  .app-vr-cursor {
    content: ' ';
    margin-top: 7px;
    width: 8px;
    height: 11px;
    background: green;
    display: inline-block;
    animation: blinker 1s linear infinite;
  }

  .app-vr-power-button {
    width: 26px;
    height: 36px;
    z-index: 9999;
    cursor: pointer;
    transform-origin: bottom left;
    -ms-transform: skew(14deg, 0deg);
    -webkit-transform: skew(14deg, 0deg);
    transform: skew(14deg, 0deg);
  }

  .app-vr-power-button-powering {
    width: 26px;
    height: 36px;
    z-index: 9999;
    cursor: pointer;
    box-shadow: 0 0 50px 15px orangered; 
    animation: blinker 2s linear infinite;
    transform-origin: bottom left;
    -ms-transform: skew(14deg, 0deg);
    -webkit-transform: skew(14deg, 0deg);
    transform: skew(14deg, 0deg);
  }

  .app-vr-power-button-on {
    width: 26px;
    height: 36px;
    z-index: 9999;
    cursor: pointer;
    box-shadow: 0 0 50px 15px lightblue;
    transform-origin: bottom left;
    -ms-transform: skew(14deg, 0deg);
    -webkit-transform: skew(14deg, 0deg);
    transform: skew(14deg, 0deg);
  }

  .app-vr-console-text {
    line-height: 15px;
    font-family: 'Courier New', Courier, monospace;
    font-size: 11px;
  }  
  
  #scene-container {
    position: fixed;
    height: 800px;
    width: 1000px;
  }
  
  #vrTextOverlay {
    color: green; 
    font-weight:bold; 
    z-index: 100;
    width: 100%;
    min-width: 100%;
    text-wrap: nowrap;
    font-family: 'Courier New', monospace;
    font-size: 11px;
    padding-top: 7px;
    position: absolute;
    left: -10px;
  }

  #vrInvalidInput {
    position: fixed;
    color: red;
    top: 110px;
    left: 80px;
    background: #e2e2e2;
    border: 2px solid red;
  }

  #vrTextInput {
    background: transparent;
    font-family: 'Courier New', Courier, monospace;
    font-size: 11px;
    color: transparent;
    border: none;
    caret-color: transparent;
  }

  #vrTextInput:focus {
    outline: none;
  }

  
  #type-here {
    background-color: black;
    color: green;
    border: none;
    width: 60vw;
    position: absolute;
  }
  #type-here:focus {
    outline: none;
    caret-color: transparent;
    color: transparent;
  }
  
  @keyframes blinker {
    50% {
      opacity: 0;
    }
  }

  @keyframes typing {
    from { width: 0 }
    to { width: 100% }
  }
  
  /* scrollbar handling */
  
  /* width */
  ::-webkit-scrollbar {
    width: 5px;
  }
  
  /* Track */
  ::-webkit-scrollbar-track {
    background: black;
  }
  
  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: green;
  }
  
  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: #555;
  }